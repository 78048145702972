import React from "react"
import 'twin.macro'
import { graphql } from "gatsby"
import { H2, H3, P } from "../components/typography"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Footer from "../components/footer"

const AboutPage = ({ data }) => {
  const title = data.site.siteMetadata.title

  const principles = [
    {
      title: 'Useful',
      summary: 'We are building the thing we wanted to exist, so we—and you—can use it.',
    },
    {
      title: 'Fast',
      summary: 'Find what you’re looking for, then leave here and go there.',
    },
    {
      title: 'No ads',
      summary: 'No ads, no bloat, no conflicts of interest.',
    },
    {
      title: 'Taste',
      summary: 'From the site to the content, ThirdPlaces reflects our taste.',
    },
  ]
  return (
    <Layout styleContext="page">
      <Seo title="About"/>
      <div tw="pt-24 pb-16 px-4 md:(px-16 py-16) lg:(px-0)">
        <Section>
          <div tw="md:(grid gap-4 grid-cols-2)">
            <h1 tw="font-serif text-4xl md:(text-5xl) lg:(text-6xl)">A non-exhaustive, <em>not&nbsp;exhausting</em>, guide.</h1>
            <div>
              <P tw="my-4">{title} is written from the firsthand experiences of a small team. It is our attempt to highlight transparency and integrity in Perth’s food scene, free from advertising and sponsored posts.</P>
              <P tw="my-4">We built ThirdPlaces not as a definitive guide to good food in Perth but as a reflection of our own values toward quality, sustainability, provenance, and traceability.</P>
              <P tw="my-4">ThirdPlaces in particular seeks to highlight small owner-operators, especially those who are often too busy keeping their dream alive to be able to conduct their own high-level marketing.</P>
            </div>
          </div>
        </Section>
        <Section>
          <H2 tw="mb-2 mt-4 md:(mb-4 mt-0)">Guiding principles</H2>
          <div tw="grid gap-6 py-4 grid-cols-2 lg:(grid-cols-4)">
            {principles.map(({ title, summary }) => (
              <div>
                <H3>{title}</H3>
                <P>{summary}</P>
              </div>
            ))}
          </div>
        </Section>
        <Section>
          <div tw="md:(grid gap-4 grid-cols-2)">
            <H2 tw="mb-2 mt-4 md:(mb-4 mt-0)">What is a ‘Third Place’</H2>
            <div tw="space-y-4">
              <P>The Third Place is a term coined by Ray Oldenburg in 1989’s The Great Good Place. Oldenburg’s notion of the Third Place describes a place of easy access, welcome, and a place of level ground which does not qualify entry based on social&nbsp;class.</P>
              <P>ThirdPlaces is a living list of venues which fit our definition of the third place within a food context. Great food is a necessity for our listing, but so too is a welcoming, unpretentious atmosphere. Third places in our list are places you can catch up with old friends, and discover with new ones.</P>
              <P>Since third places must be social levellers, we have taken care to be transparent about the costs associated with the venues we've listed. We have endeavoured to highlight owner-operator, family-owned and small businesses, and we have taken strides to include great quality food and hospitality experiences at all price points.</P>
            </div>
          </div>
        </Section>
      </div>
      <Footer/>
    </Layout> 
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default AboutPage
